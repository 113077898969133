import { render, staticRenderFns } from "./TextCom.vue?vue&type=template&id=108678c1&scoped=true&"
import script from "./TextCom.vue?vue&type=script&lang=js&"
export * from "./TextCom.vue?vue&type=script&lang=js&"
import style0 from "./TextCom.vue?vue&type=style&index=0&id=108678c1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "108678c1",
  null
  
)

export default component.exports