import { render, staticRenderFns } from "./d2-source.vue?vue&type=template&id=ebced34e&scoped=true&"
import script from "./d2-source.vue?vue&type=script&lang=js&"
export * from "./d2-source.vue?vue&type=script&lang=js&"
import style0 from "./d2-source.vue?vue&type=style&index=0&id=ebced34e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebced34e",
  null
  
)

export default component.exports